
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { friendlyDate } from '@/utils/time'

@Component
export default class TripMonitoringReservationDetails extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly tripMonitoringDetail: any

  friendlyDate = friendlyDate

  get managedId(): string {
    return this.tripMonitoringDetail?.managedId?.split('.')[0]
  }

  get pickupDate(): string {
    return this.friendlyDate(this.tripMonitoringDetail?.pickupDate)
  }

  get customerName(): string {
    return this.tripMonitoringDetail?.customerName
  }

  get referralPassengerCount(): number {
    return this.tripMonitoringDetail?.referralPassengerCount
  }

  get pickupTime(): string {
    return this.getLocalTime(this.tripMonitoringDetail?.pickupDate)
  }

  get customerAccountName(): string {
    return this.tripMonitoringDetail?.customerAccountName
  }

  get tripTypeLabel(): string {
    return this.tripMonitoringDetail?.tripTypeLabel
  }

  get firstPickupAddressName(): string {
    return this.tripMonitoringDetail?.firstPickupAddressName
  }

  get tierLabel(): string {
    return this.tripMonitoringDetail?.tierLabel
  }

  getDate(timestamp) {
    return DateTime.fromISO(timestamp).setZone('local').toLocaleString({
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    })
  }

  getLocalTime(timestamp) {
    if (!timestamp) {
      return null
    }
    return DateTime.fromISO(timestamp).setZone('local').toLocaleString({
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    })
  }

}
