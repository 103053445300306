
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import drivers from '@/services/drivers'
import { EventBus } from '@/utils/event-bus'
import { getDriverPhoneNumber } from '@/utils/tripAssignments'
import { DateTime } from 'luxon'
import { datetimeToShortLocalizedDateTime } from '@/utils/time'
import { ReferredProvider } from '@/models/dto'
import { Driver } from '@/models/dto/Driver'
import { Vehicle } from '@/models/dto/Vehicle'

@Component
export default class TripMonitoringContactLogsTab extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly contactLogs: Array<any>

  headers = [
        { text: 'Log submitted at', value: 'createdAt' },
        { text: 'Log', value: 'text' },
        { text: 'Contact Method', value: 'contactMethodString' },
        { text: 'Rep', value: 'representativeName' },
        { text: 'Contact Time', value: 'contactTime' },
        { text: 'Reminder Time', value: 'reminderTime' },
      ]

  pagination = {
    descending: true,
    page: 1,
    rowsPerPage: 10,
    sortBy: 'createdAt',
  }

  formatTime(datetime) {
    if (!datetime) {
      return '-'
    }
    return DateTime.fromISO(datetime).toFormat('t ZZZZ')
  }

  formatDatetime(datetime) {
    if (!datetime) {
      return '-'
    }
    return DateTime.fromISO(datetime).toFormat('t ZZZZ, MM/dd/yyyy')
  }
}
