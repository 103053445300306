
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { TrackingDevice, Vehicle, VehicleAssignment } from '@/models/dto'
import { DateTime } from 'luxon'
import { timeAgo } from '@/utils/time'

@Component
export default class TripMonitoringReservationDetails extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly vehicleAssignments: Array<
    VehicleAssignment
  >
  @Prop({ type: Array, default: () => [] }) readonly trackingDevices: Array<
    TrackingDevice
  >

  get vehicles(): Array<Vehicle> {
    return this.vehicleAssignments.map((va) => va.vehicle)
  }

  getEldType(vehicle) {
    return this.capitalizeFirstLetter(
      this.selectTrackingDevice(vehicle.vehicleId)?.eldType?.label
    )
  }
  getDeviceId(vehicle) {
    return this.selectTrackingDevice(vehicle.vehicleId)?.deviceId
  }
  getLastReportTime(vehicle) {
    return this.selectTrackingDevice(vehicle.vehicleId)?.lastReportTime
  }

  selectTrackingDevice(vehicleId) {
    return this.trackingDevices
      .filter((item) => item.vehicleId === vehicleId)
      .reduce((latest, current) => {
        return !latest ||
          new Date(current.lastReportTime) > new Date(latest.lastReportTime)
          ? current
          : latest
      }, null)
  }

  getTimeAgo(timestamp) {
    if (timestamp) {
      return timeAgo(timestamp)
    }
    return 'N/A'
  }

  capitalizeFirstLetter(label) {
    if (!label) {
      return ''
    }
    const lowerCase = label.toLowerCase()
    return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
  }

  isEligibleToTrack(vehicle) {
    const lastReportTime = this.getLastReportTime(vehicle)

    if (!lastReportTime) {
      return false
    }

    const lastReportDate = DateTime.fromISO(lastReportTime)
    const sevenDaysAgo = DateTime.now().minus({ days: 7 })
    return lastReportDate >= sevenDaysAgo
  }
}
