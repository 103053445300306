import { ApiResult } from "./ApiResult";
import { DateTime } from 'luxon'

export enum TripMonitoringStatusLevel {
  Success = 'SUCCESS',
  Warning = 'WARNING',
  Failure = 'FAILURE'
}

export interface TripMonitoringStatus {
  label: string,
  statusLevel: TripMonitoringStatusLevel,
}

export interface TripMonitoringReferral {
  reservationId: number,
  managedId: string,
  pickupTime: DateTime,
  companyId: number,
  companyName: string,
  driverStatus: TripMonitoringStatus,
  vehicleStatus: TripMonitoringStatus,
  trackingStatus: TripMonitoringStatus,
  contactStatus: string,
  lastContact: DateTime,
  logs: string,
  assignedTo: string,
  assignedDriverCount: number
}

export interface TripMonitoringTableResult extends ApiResult {
  referrals: TripMonitoringReferral[]
}

export interface WindowWatcherUser {
  userId: number,
  firstName: string | null,
  lastName: string | null,
}

export interface WindowWatcherUserListResult extends ApiResult {
  users: WindowWatcherUser[]
}

export interface ReferralAssignment {
  userId: number,
  referralIds: number[]
}

export interface CompanyContactInfo {
  contactPreferenceText: string | null,
  email: string,
  phone: string,
  opsPhone: string | null,
  partnerPhone: string | null,
  smsPhone: string | null
}

export interface DriverContactInfo {
  firstName: string,
  lastName: string,
  email: string,
  phone: string | null
}
export interface ReferralDriverContactInfo {
  contactPreferenceText: string | null,
  driverContactInfo: DriverContactInfo[]
}
