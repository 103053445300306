import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { CompanyContactInfo, ReferralAssignment, ReferralDriverContactInfo, WindowWatcherUserListResult } from '@/models/dto/TripMonitoring'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export const getWindowWatcherUsers = async(): Promise<AxiosResponse<WindowWatcherUserListResult>> => {
  const url = `https://${baseUrl()}/user/windowWatchers`
  return httpService.get(url)
}

export const assignReferralToRepresentative = async(userId: number, referralIds: number[]): Promise<AxiosResponse<ApiResult>> => {
  const referralAssignment: ReferralAssignment = {
    userId,
    referralIds
  }
  const url = `https://${baseUrl()}/tripMonitoring/referral/representative`
  return httpService.post(url, referralAssignment)
}

export const getCompanyContactInfo = async(companyId: number): Promise<AxiosResponse<CompanyContactInfo>> => {
  const url = `https://${baseUrl()}/tripMonitoring/company/${companyId}/contactInfo`
  return httpService.get(url)
}

export const getReferralDriverContactInfo = async(companyId: number, referralId: number): Promise<AxiosResponse<ReferralDriverContactInfo>> => {
  const url = `https://${baseUrl()}/tripMonitoring/company/${companyId}/referral/${referralId}/driverContactInfo`
  return httpService.get(url)
}


export const createContactLog = async (
  payload
) => {
  const url = `https://${baseUrl()}/tripMonitoring/referral/contactLog`
  return httpService.post(url, payload)
}
