
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AmenityType, Driver, ReferredProvider, TripMonitoringVehicle, Vehicle } from '@/models/dto'
import { DateTime } from 'luxon'
import { AmenityTypeId, guaranteedAmenityTypeIds, VehicleAmenityTypeIds } from '@/utils/enum'

@Component
export default class TripMonitoringVehicleDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly referral: ReferredProvider
  @Prop({ type: Array, default: () => [] }) readonly driverList: Array<Driver>
  @Prop({ type: Array, default: () => [] }) readonly vehicleList: Array<Vehicle>
  @Prop({ type: Array, default: () => [] }) readonly tripMonitoringVehicles: Array<TripMonitoringVehicle>
  @Prop({ type: Object, default: () => {} }) readonly tripMonitoringDetail: any
  @Prop({ type: Array, default: () => [] }) readonly tripAmenities: Array<AmenityType>

  get capacityDisplay(): string {
    return `${this.tripMonitoringVehicles.reduce((total, vehicle) => total + vehicle.passengerCapacity, 0)} / ${this.tripMonitoringDetail.referralPassengerCount}`
  }

  get showCapacityWarning(): boolean {
    return this.tripMonitoringVehicles.reduce((total, vehicle) => total + vehicle.passengerCapacity, 0) < this.tripMonitoringDetail.referralPassengerCount
  }

  get guaranteedVehicleBasedTripAmenities(): Array<AmenityType> {
    return this.tripAmenities
      .filter(amenity => Object.values(VehicleAmenityTypeIds).includes(amenity.id))
      .filter(amenity => Object.values(guaranteedAmenityTypeIds).includes(amenity.id))
  }

  get unassignedCount(): number {
    return this.tripMonitoringDetail.requiredVehicles - this.tripMonitoringVehicles.length
  }

  showVehicleYearWarning(vehicleYear: number): boolean {
    return DateTime.fromISO(vehicleYear.toString()) < DateTime.now().minus({years: 10})
  }

  formatDatetime(datetime) {
    if (!datetime) {
      return '-'
    }
    return DateTime.fromISO(datetime).toFormat('t ZZZZ, MM/dd/yyyy')
  }

  openAssignmentSidebar() {
    const component = () => import('./DispatchReservationSidebar.vue')

    const allDrivers = this.driverList.map((driver) => ({
      ...driver,
      name: `${driver.firstName} ${driver.lastName}`,
    }))
    const allVehicles = this.vehicleList.map((vehicle) => ({
      ...vehicle,
      name: `${vehicle.vehicleName} ${vehicle.vehicleModel}`,
    }))

    this.$store.dispatch('app/openSidebarDialog', {
      component,
      data: {
        title: 'Add Assignments',
        reservationId: this.referral.reservationId,
        managedId: this.referral.managedId,
        referral: this.referral,
        isAssignmentsOnly: true,
        vehicles: allVehicles,
        drivers: allDrivers,
        garages: [],
        companyId: this.referral?.companyId,
      },
    })
  }
}
