
import { DateTime } from 'luxon'
import TripMonitoringRepresentativeAssignmentSidebar from './TripMonitoringRepresentativeAssignmentSidebar.vue'
import { Trip } from '@/classes/Trip'
import { authComputed } from '@/state/helpers'
import TripMonitoringAccordion from '@/components/TripMonitoringAccordion.vue'
import {
  TripMonitoringStatusLevel,
  TripMonitoringStatus,
} from '@/models/dto/TripMonitoring'
import { ContactType } from '@/utils/enum'

export default {
  components: {
    TripMonitoringRepresentativeAssignmentSidebar,
    TripMonitoringAccordion,
  },
  props: {
    filteredReferrals: {
      type: Array,
      default: () => [],
    },
    selectedReferralIdsSet: {
      type: Set,
      required: true,
    },
    currentItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      expand: false,
      rowsPerPageItems: [...Array(10).keys()].map((x) => (x + 1) * 10),
      headers: [
        { text: 'Reservation ID', value: 'managedId' },
        { text: 'Pickup Time', value: 'pickupTime' },
        { text: 'Company Name', value: 'companyName' },
        { text: 'Driver Status', value: 'driverStatus.label' },
        { text: 'Vehicle Status', value: 'vehicleStatus.label' },
        { text: 'Tracking Status', value: 'trackingStatus.label' },
        { text: 'Contact', value: 'reminderTime' },
        { text: 'Last Contact', value: 'lastContact' },
        { text: 'Notes', value: 'logs' },
        { text: 'Support Team', value: 'supportTeamClassificationLabel' },
        { text: 'Tier', value: 'tierLabel' },
        { text: 'Assigned To', value: 'assignedTo' },
      ],
      referrals: [],
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'pickupTime',
      },
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    sortByColumn(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
      this.$forceUpdate()
    },
    getLocalTime(timestamp) {
      if (!timestamp) {
        return null
      }
      return DateTime.fromISO(timestamp).setZone('America/New_York').toLocaleString({
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      })
    },
    getLocalDateTime(timestamp) {
      if (!timestamp) {
        return null
      }
      return DateTime.fromISO(timestamp)
        .setZone('America/New_York')
        .toFormat("hh:mm a 'on' MMM dd, yyyy ZZZZ")
    },
    toggleAllVisibleReferrals() {
      this.$emit('toggle-all-visible-referrals')
    },
    toggleReferral(referralId) {
      this.$emit('toggle-referral', referralId)
    },
    openAssignToRepSidebar() {
      this.$store.dispatch('app/openSidebarDialog', {
        component: TripMonitoringRepresentativeAssignmentSidebar,
        data: {
          title: 'Assign to Rep',
          selectedReferralIdsSet: this.selectedReferralIdsSet,
        },
      })
    },
    getStatusClass(status) {
      return {
        'successful-status':
          status.statusLevel === TripMonitoringStatusLevel.Success,
        'warning-status':
          status.statusLevel === TripMonitoringStatusLevel.Warning,
        'failure-status':
          status.statusLevel === TripMonitoringStatusLevel.Failure,
      }
    },
    getContactText(reminderTime) {
      if (!reminderTime) {
        return 'Contact'
      } else {
        return `Contact ${this.getLocalTime(reminderTime)}`
      }
    },
    isTimestampPast(timestamp) {
      const now =  DateTime.now().setZone('America/New_York');
      const setTime = DateTime.fromISO(timestamp);
      return setTime < now;
    },
    openContactSidebar(mode, item = null) {
      const component = () =>
        import('@/components/TripMonitoringContactSidebar.vue')
      let title = ''
      switch (mode) {
        case ContactType.Driver:
          title = 'Contact Driver'
          break
        case ContactType.Operator:
          title = 'Contact Operator'
          break
        default:
          console.error('Unexpected mode: ', mode)
          return
      }
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          reservationId: item.reservationId,
          companyId: item.companyId,
          title: title,
          mode: mode,
        },
        persistent: true,
        component,
      })
    },
  },
  mounted() {
    // This should not be replicated anywhere if possible.
    // This fix is a workaround to replicate the 'current-items' event in
    // Vuetify 2. This should be replaced when we update.
    this.$watch(
      () => {
        return this.$refs.tripMonitoringDataTable.filteredItems
      },
      (filteredItems) => {
        this.$emit('current-items', filteredItems)
      }
    )
  },
}
