
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { TripMonitoringCompany } from '@/models/dto'
import { getTierLabelByQuality, isCompanyTierLessThanReservationTierByLabels } from '@/utils/company'
import { CompanyTierLabel } from '@/utils/enum'

@Component
export default class TripMonitoringProvider extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly company: TripMonitoringCompany
  @Prop({ type: Object, default: () => {} }) readonly tripMonitoringDetail: any
  getTierLabelByQuality = getTierLabelByQuality

  get shouldShowWarning() {
    return isCompanyTierLessThanReservationTierByLabels(this.getTierLabelByQuality(this.company.quality) as CompanyTierLabel, this.tripMonitoringDetail.tierLabel)
  }

  get carrierOperationLabel(): string {
    if (this.company.dotInterstateAllowed === null) {
      return 'N/A'
    }
    return this.company.dotInterstateAllowed ? 'Interstate' : 'Intrastate Only'
  }
}
