
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { EventBus } from '@/utils/event-bus'
import { WindowWatcherUser } from '@/models/dto/TripMonitoring'
import CRSelect from '@/components/CRSelect.vue'
import CRButton from '@/cr/components/CRButton.vue'

import { getWindowWatcherUsers, assignReferralToRepresentative } from '@/services/tripMonitoring'
import { AxiosError } from 'axios'


@Component({
  components: {
    CRSelect,
    CRButton
  }
})
export default class TripMonitoringRepresentativeAssignmentSidebar extends Vue {

  @Prop({ type: Set, required: true }) readonly selectedReferralIdsSet: Set<number>

  userId:number | null = null
  windowWatcherUsers: WindowWatcherUser[] = []


  getWindowWatcherUsers = getWindowWatcherUsers
  assignReferralToRepresentative = assignReferralToRepresentative

  async created(): Promise<void> {
    const result = await this.getWindowWatcherUsers()
    this.windowWatcherUsers = result.data.users
  }

  async assignTripsToUser(): Promise<void> {
    try {
      const response = await this.assignReferralToRepresentative(this.userId, Array.from(this.selectedReferralIdsSet))
      if (response.status === 200) {
        EventBus.$emit('global-table-view-refresh', `Assigned ${this.selectedReferralIdsSet.size} trips to ${this.assignedUser.text}`)
      } else {
          this.$store.dispatch('app/showAlert', {
            message: `Error assigning representative to trips`,
        })
      }
    } catch (e: any) {
      this.$store.dispatch('app/showAlert', {
            message: e.message,
            type: 'error'
      })
    }

  }

  get windowWatcherItems() {
    return this.windowWatcherUsers.map((windowWatcherUser) => {
      const value = windowWatcherUser.userId
      let text
      if(windowWatcherUser.firstName === null) {
        text = `User id: ${windowWatcherUser.userId}`
      } else if (windowWatcherUser.lastName === null) {
        text = windowWatcherUser.firstName
      } else {
        text = `${windowWatcherUser.firstName} ${windowWatcherUser.lastName}`
      }

      return {
        text,
        value
      }
    })
  }

  get assignedUser() {
    return this.windowWatcherItems.filter((item) => item.value === this.userId)[0]
  }
}

