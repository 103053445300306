
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import drivers from '@/services/drivers'
import { EventBus } from '@/utils/event-bus'
import { getDriverPhoneNumber } from '@/utils/tripAssignments'
import { DateTime } from 'luxon'
import { datetimeToShortLocalizedDateTime } from '@/utils/time'
import { ReferredProvider } from '@/models/dto'
import { Driver } from '@/models/dto/Driver'
import { Vehicle } from '@/models/dto/Vehicle'

@Component
export default class TripMonitoringDriverTab extends Vue {
  @Prop({ type: Object, default: () => undefined }) readonly referral: ReferredProvider
  @Prop({ type: Array, default: () => [] }) readonly driverList: Array<Driver>
  @Prop({ type: Array, default: () => [] }) readonly vehicleList: Array<Vehicle>
  @Prop({ type: Object, default: () => undefined }) readonly model: any

  getDriverPhoneNumber = getDriverPhoneNumber
  datetimeToShortLocalizedDateTime = datetimeToShortLocalizedDateTime

  handleInputClick() {
    const component = () => import('./DispatchReservationSidebar.vue')

    const allDrivers = this.driverList.map((driver) => ({
      ...driver,
      name: `${driver.firstName} ${driver.lastName}`,
    }))
    const allVehicles = this.vehicleList.map((vehicle) => ({
      ...vehicle,
      name: `${vehicle.vehicleName} ${vehicle.vehicleModel}`,
    }))

    this.$store.dispatch('app/openSidebarDialog', {
      component,
      data: {
        title: 'Add Assignments',
        reservationId: this.referral.reservationId,
        managedId: this.referral.managedId,
        referral: this.referral,
        isAssignmentsOnly: true,
        vehicles: allVehicles,
        drivers: allDrivers,
        garages: [],
        companyId: this.referral?.companyId,
      },
    })
  }

  formatDatetime(datetime) {
    if (!datetime) {
      return '-'
    }
    return DateTime.fromISO(datetime).toFormat('t ZZZZ, MM/dd/yyyy')
  }

  beforeDestroy() {
    EventBus.$off('reservations-refreshed')
  }

  created() {
    EventBus.$on('reservations-refreshed', () => {
      this.$store.dispatch('app/closeSidebarDialog')
    })
  }

}
